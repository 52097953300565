import { entries, transform } from "lodash";
import Stickyfill from "stickyfilljs";
import Swiper from "swiper/swiper-bundle";
import { Fancybox } from "@fancyapps/ui";


/* Custom General jQuery
/*--------------------------------------------------------------------------------------------------------------------------------------*/
; (function ($, window, document, undefined) {
	//Genaral Global variables
	//"use strict";
	var $win = $(window);
	var $doc = $(document);
	var $winW = function () { return $(window).width(); };
	var $winH = function () { return $(window).height(); };
	var $screensize = function (element) {
		$(element).width($winW()).height($winH());
	};
	Fancybox.bind("[data-fancybox]", {
	// Your custom options
	});

	var screencheck = function (mediasize) {
		if (typeof window.matchMedia !== "undefined") {
			var screensize = window.matchMedia("(max-width:" + mediasize + "px)");
			if (screensize.matches) {
				return true;
			} else {
				return false;
			}
		} else { // for IE9 and lower browser
			if ($winW() <= mediasize) {
				return true;
			} else {
				return false;
			}
		} 
	};
	$doc.ready(function () {
		/*--------------------------------------------------------------------------------------------------------------------------------------*/
		// Remove No-js Class
		$("html").removeClass('no-js').addClass('js');



		/* Get Screen size
		---------------------------------------------------------------------*/
		$win.on('load', function () {
			$win.on('resize', function () {
				$screensize('your selector');
			}).resize();
		});


		/* Menu ICon Append prepend for responsive
		---------------------------------------------------------------------*/
		$(window).on('resize', function(){
			if (screencheck(991)) {
				if(!$('#menu').length){
					$('#mainmenu').prepend('<a href="#" id="menu" class="menulines-button"><span class="menulines"></span></a>');
				}
			} else {
				$("#menu").remove();
			}
		}).resize();


		/* Tab Content box 
		---------------------------------------------------------------------*/
		var tabBlockElement = $('.tab-data');
		$(tabBlockElement).each(function () {
			var $this = $(this),
				tabTrigger = $this.find(".tabnav li"),
				tabContent = $this.find(".tabcontent");
			var textval = [];
			tabTrigger.each(function () {
				textval.push($(this).text());
			});
			$this.find(tabTrigger).first().addClass("active");
			$this.find(tabContent).first().show();

			$(tabTrigger).on('click', function () {
				$(tabTrigger).removeClass("active");
				$(this).addClass("active");
				$(tabContent).hide().removeClass('visible');
				var activeTab = $(this).find("a").attr("data-rel");
				$this.find('#' + activeTab).fadeIn('normal').addClass('visible');

				return false;
			});

			var responsivetabActive = function () {
				if (screencheck(767)) {
					if (!$this.find('.tabMobiletrigger').length) {
						$(tabContent).each(function (index) {
							$(this).before("<h2 class='tabMobiletrigger'>" + textval[index] + "</h2>");
							$this.find('.tabMobiletrigger:first').addClass("rotate");
						});
						$('.tabMobiletrigger').click('click', function () {
							var tabAcoordianData = $(this).next('.tabcontent');
							if ($(tabAcoordianData).is(':visible')) {
								$(this).removeClass('rotate');
								$(tabAcoordianData).slideUp('normal');
								//return false;
							} else {
								$this.find('.tabMobiletrigger').removeClass('rotate');
								$(tabContent).slideUp('normal');
								$(this).addClass('rotate');
								$(tabAcoordianData).not(':animated').slideToggle('normal');
							}
							return false;
						});
					}

				} else {
					if ($('.tabMobiletrigger').length) {
						$('.tabMobiletrigger').remove();
						tabTrigger.removeClass("active");
						$this.find(tabTrigger).removeClass("active").first().addClass('active');
						$this.find(tabContent).hide().first().show();
					}
				}
			};
			$(window).on('resize', function () {
				if (!$this.hasClass('only-tab')) {
					responsivetabActive();
				}
			}).resize();
		});

		/* Accordion box JS
		---------------------------------------------------------------------*/
		$('.accordion-databox').each(function () {
			var $accordion = $(this),
				$accordionTrigger = $accordion.find('.accordion-trigger'),
				$accordionDatabox = $accordion.find('.accordion-data');

			$accordionTrigger.first().addClass('open');
			$accordionDatabox.first().show();

			$accordionTrigger.on('click', function (e) {
				var $this = $(this);
				var $accordionData = $this.next('.accordion-data');
				if ($accordionData.is($accordionDatabox) && $accordionData.is(':visible')) {
					$this.removeClass('open');
					$accordionData.slideUp(400);
					e.preventDefault();
				} else {
					$accordionTrigger.removeClass('open');
					$this.addClass('open');
					$accordionDatabox.slideUp(400);
					$accordionData.slideDown(400);
				}
			});
		});


		/* Mobile menu click
		---------------------------------------------------------------------*/
		$(document).on('click', "#menu", function () {
			$(this).toggleClass('menuopen');
			$(this).next('ul').slideToggle('normal');
			return false;
		});


		/* Header Sticky
		---------------------------------------------------------------------*/
		if ($("#header").length) {
			$(window).scroll(function () {
				var headerHeight = $('#header').outerHeight();
				if ($(this).scrollTop() > headerHeight) {
					$("#header").addClass("sticky");
				} else {
					$("#header").removeClass("sticky");
				}
			});
			var header = document.querySelectorAll('#header');
			Stickyfill.add(header);
		}

		if($('#header .link-group .button').length){
			const button =  $('#header .link-group .button');
			let isAppend = false;
			$win.on('resize', function(){
				if(screencheck(568)){
					if(!isAppend){
						$('#mainmenu > ul').append(
							`<li class='appendElem'>
								<a href="${button.attr("href")}">${button[0].innerText}</a>
							</li>`
						)
						isAppend = true;
					}
				}else{
					
					if(isAppend){
						$('.appendElem').remove()
						isAppend = false;
					}
				}
			});
		}

		// partner slider
		var partnerSwiper = new Swiper(".partner-swiper", {
			slidesPerView: 2,
			spaceBetween: 30,
			breakpoints: {
				568:{
					slidesPerView: 3, 
				},
				768: {
					slidesPerView: 4,
					spaceBetween: 30
				},
				992: {
					slidesPerView: 5,
				},

			}
		});

		// testimonial slider
		var testimonialSwiper = new Swiper(".testimonial-slider", {
			slidesPerView: "auto",
			grabCursor: true,
			spaceBetween: 30,
			navigation: {
				nextEl: ".testimonial-pagination .swiper-button-next",
				prevEl: ".testimonial-pagination .swiper-button-prev",
			},
		});

		// testimonial slider
		var clientsTestimonialSwiper = new Swiper(".clients-testimonial-slider", {
			slidesPerView: 1,
			spaceBetween: 30,
			navigation: {
				nextEl: ".clients-testimonial-pagination .swiper-button-next",
				prevEl: ".clients-testimonial-pagination .swiper-button-prev",
			},
		});
		let isBtnAppend = false;
		$(window).on("resize",function() {
			if(screencheck(768)){
				if($('.clients-succeed-sec .clients-testimonial-pagination').length && !isBtnAppend){
					$('.clients-succeed-sec').append($('.clients-testimonial-pagination'))
					isBtnAppend = true;
				}
			}else{
				if(isBtnAppend){
					$('.clients-succeed-sec .section-title').append($('.clients-testimonial-pagination'))
					isBtnAppend = false;
				}
			}
		})

		// feature slider
		var featureSwiper = new Swiper(".feature-slider", {
			slidesPerView: 1,
			grabCursor: true,
			spaceBetween: 15,
			breakpoints:{
				568:{
					slidesPerView: 1.5,
				},
				768:{
					slidesPerView: 2,
					spaceBetween: 30,
				},
				992:{
					slidesPerView: 2.5,
				},
				1200:{
					slidesPerView: 3,
				}
			}
		});


		// key-benifits
		var keyBenifitSwiper = new Swiper(".key-benifit-slider", {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: false,
			breakpoints:{
				568:{
					slidesPerView: 1.5,
				},
				768:{
					slidesPerView: 2,
				},
				992:{
					slidesPerView: 2.5,
				},
				1200:{
					slidesPerView: 3,
				},
				1400:{
					slidesPerView: 3.2,
				}
			}
		});


		// blog slider
		var blogSwiper = new Swiper(".blog-slider", {
			spaceBetween: 30,
			effect: "fade",
			navigation: {
				nextEl: ".blog-pagination .swiper-button-next",
				prevEl: ".blog-pagination .swiper-button-prev",
			},
		});

		// partner slider
		var companyStatistic = new Swiper(".company-statistic-swiper", {
			slidesPerView: 2,
			spaceBetween: 30,
			breakpoints: {
				568:{
					slidesPerView: 3, 
				},
				768: {
					slidesPerView: 4,
					spaceBetween: 20
				},
				992: {
					slidesPerView: 5,
				},

			}
		});

		// hover style for product box 
		$(".our-products").hover(function(){
			let $this = $(this);
			let isTransition = false;
			$this.addClass('hover')

			if($this.children("figure").length){
				$this.children("figure").css({"margin-top":"-60px" , "opacity": "0"})
				
				$this.children("figure")[0].addEventListener("transitionstart",function(e) {
					if(!isTransition){
						setTimeout(() => {
							if($this.hasClass("hover")){
								$this.children("figure").css("display","none")
								$this.children("p").fadeIn(150).css("display","-webkit-box")	
							}
						},200);
						isTransition = true
					}
				})
			}else{
				$this.children("p").fadeIn(500).css("display","-webkit-box")	
			}

		}, function() {
			let $this = $(this);
			$this.removeClass('hover')
			
			$this.children("p").fadeOut(200,function(){ 
				$this.children("figure").css({"display" : "block" })
				setTimeout(() => {
					$this.children("figure").css({"opacity": "1" ,"margin-top":"0" })
				},0);
			})
		})
		

		$(window).scroll(function () {
			$('.parallax-img').each(function () {
				if (($(this).offset().top - (window.innerHeight)) < $(window).scrollTop()) {
					var difference = $(window).scrollTop() - $(this).offset().top ;
					
					var half = -(difference / 8) + 'px',
						transform = 'translate3d( 0, ' + half + ',0)';

					$(this).find('img').css('transform', transform);
				} else {
					$(this).find('img').css('transform', 'translate3d(0,0,0)');
				}
			});
		});
	

		// custom select js
			var customSelect = $(".custom-select");

			customSelect.each(function() {
			var thisCustomSelect = $(this),
				options = thisCustomSelect.find("option"),
				firstOptionText = options.first().text();

			var selectedItem = $("<div></div>", {
				class: "selected-item"
			})
				.appendTo(thisCustomSelect)
				.text(firstOptionText);

			var allItems = $("<div></div>", {
				class: "all-items all-items-hide"
			}).appendTo(thisCustomSelect);

			options.each(function() {
				var that = $(this),
				optionText = that.text();

				var item = $("<div></div>", {
				class: "item",
				on: {
					click: function() {
					var selectedOptionText = that.text();
					selectedItem.text(selectedOptionText).removeClass("arrowanim");
					allItems.addClass("all-items-hide");
					}
				}
				})
				.appendTo(allItems)
				.text(optionText);
			});
			});

			var selectedItem = $(".selected-item"),
			allItems = $(".all-items");

			selectedItem.on("click", function(e) {
			var currentSelectedItem = $(this),
				currentAllItems = currentSelectedItem.next(".all-items");

			allItems.not(currentAllItems).addClass("all-items-hide");
			selectedItem.not(currentSelectedItem).removeClass("arrowanim");

			currentAllItems.toggleClass("all-items-hide");
			currentSelectedItem.toggleClass("arrowanim");

			e.stopPropagation();
			});

			$(document).on("click", function() {
			var opened = $(".all-items:not(.all-items-hide)"),
				index = opened.parent().index();

			customSelect
				.eq(index)
				.find(".all-items")
				.addClass("all-items-hide");
			customSelect
				.eq(index)
				.find(".selected-item")
				.removeClass("arrowanim");
			});

			 $(document).on('click', '.all-items .item', function(){
				var $thisInd = $(this).index(),
					$thisSel = $(this).parents('.custom-select').find('select');
					$thisSel.find("option").removeAttr("selected") 
					$thisSel[0][$thisInd].setAttribute('selected', 'selected');
					$thisSel.val($thisSel[0][$thisInd].value)
					digitalfilter($thisSel[0][$thisInd].value);
			})


			// active navbar link based on page 
			$(".current-menu-item a").addClass("active");



			// Navbar hover menu
			$('#mainmenu .has-menu>a').on("mouseenter",function() {
				if(!screencheck(992)){
					const $this = $(this);
					$this.siblings('.tooltip').fadeIn(200).css("display","flex")
				}
			})
			$('#mainmenu .has-menu').on("mouseleave", function() {
				if(!screencheck(992)){
					const $this = $(this);
					$this.children('.tooltip').fadeOut(200)
				}
			})

			$(window).on('resize',function() {
				if(screencheck(991)){
					if(!$(".sub-menu-toggle").length){
						$('#mainmenu > ul > li.has-menu').append("<span class='sub-menu-toggle'></span>")
						toggleMenu()
					}
				}else{
					if($(".sub-menu-toggle").length){
						$(".sub-menu-toggle").remove()
						$('.inner-menu').css('display', '')
						$('.has-menu').removeClass('active')
					}
				}
			}).resize()

			function toggleMenu() {
				$("#mainmenu>ul>li.has-menu .sub-menu-toggle").on("click",function() {
					$(this).parent('.has-menu').toggleClass('active').siblings('.has-menu').removeClass('active')
					$(this).siblings('.tooltip').slideToggle(200).parents('.has-menu').siblings('.has-menu').find('.tooltip').slideUp(200)
					return false;
				})
			}
		/*--------------------------------------------------------------------------------------------------------------------------------------*/
	});

	$('.parallax').each(function() {
		if(!$(this).find('.parallax-img').length){
			$(this).addClass('not-parallax-img')
		}
	})
	
	var loc = window.location.href;
	$('#mainmenu ul li').each(function() {
			if($(this).children("a").attr("href") == loc){
		if($(this).parents(".tooltip").length){
			$(this).parents(".tooltip").siblings("a").addClass("active")
		}else{
			$(this).children("a").addClass("active");
		}
		} 
	});
	// const products_elem = $("body.home .our-products-row .our-products")
	// const products_rows = Math.ceil(products_elem.length / 3)
	// if(products_elem.length % 3 === 0){
	// 	$("body.home .our-products-row").css('--rows',`${products_rows+1}`)
	// }
	// if(products_elem.length % 3 === 1){
	// 	$("body.home .our-products-row").addClass('last-elem')
	// }

	// console.log(products_rows);
	


	/*All function need to define here for use strict mode
	----------------------------------------------------------------------------------------------------------------------------------------*/

	$(".loader-btn").each(function() {
		$(this).append("<span class='inline-loader'></span>");
	})

	$("#loadmorearticle").click(function(){
		$(this).addClass("loading"); 

		var paged =$('#loadmorearticle').data('page');
		var updated_paged =paged+1;
		var display_blog =$("#loadmorearticle").data('display-blog');
		var post_count =Math.ceil($('#loadmorearticle').data('total-post')/display_blog); 

			$.ajax({
			type: "POST", 
			url: filterAjax.ajax_URL,
			data: {
				action:'loadmore_article',
				paged: updated_paged,
				display_blog: display_blog,
			},
			success: function(result){
				$("#loadmorearticle").data("page", updated_paged);
				$("#bloglistajax").append(result); 
				if(post_count==updated_paged){
					$("#loadmorearticle").hide();
				}
			},
			complete : function() {
				$("#loadmorearticle").removeClass("loading")
			}
			});
	})

	$("#prodsearch").on("keyup",productsearch);
	function productsearch(){
		var prodsearch =$("#prodsearch").val();
		$.ajax({
			type: "POST", 
			url: filterAjax.ajax_URL,
			data: {
				action:'product_search',
				prodsearch: prodsearch,
			},
			success: function(result){
				$('.livesearchdiv').css('display','block')
				$(".ajaxsearchresult").html(result);
				if(!prodsearch.length){
			$('.livesearchdiv').css('display','none')
		}
			},
		}); 
		
	}
	// View product hover style
		 
	let height = 0;	
	function autoHeight() {
		$(".digital-marketing-row .marketing-sec").each(function(index){
			let $this = $(this);
			$(".digital-marketing-row .marketing-sec")[index].style.height = "";
			
			if( this.clientHeight  > height ){
				height = this.clientHeight
			}
			if(($(".digital-marketing-row .marketing-sec").length - 1) == index){
				$this.parent('.digital-marketing-row')[0].style.height = "";

				let parentHeight = $this.parent('.digital-marketing-row')[0].clientHeight + 90 ;
				if(!screencheck(768)){
					$(".digital-marketing-row .marketing-sec").css("height", height+"px")
					$this.parent('.digital-marketing-row').css('height', parentHeight+"px")
				}
				height = 0
			}
		})
	}
	var resized
	$(window).on("resize",function(){
		clearInterval(resized);
		resized = setTimeout(autoHeight, 200);
	})	

	function digitalfilter(value){
		var digitalcat = value;
		var digitalprodid =$('.digitalcat').data('prodid');
		
		$.ajax({
			type: "POST", 
			url: filterAjax.ajax_URL,
			data: {
				action:'digitalfilter',
				digitalcat: digitalcat,
				digitalprodid: digitalprodid,
			}, 
			success: function(result){
				if(result.trim()!=''){
					$("#digitalfilterresult").html(result);
				}else{
					$("#digitalfilterresult").html('<h2 class="digitalerrormsg" align="center">Sorry! No Result Found.</h2>');
				}
				autoHeight()
			}
		}); 
	}

	
	const product_len =	$(".our-products-row .our-products").length;
	const cols = 3
	if((product_len % cols) === 0){
		$(".our-products-row").addClass("not-space")
	}else if((product_len % cols) === 1){
		$(".our-products-row").addClass("space-1")
	}

	/*--------------------------------------------------------------------------------------------------------------------------------------*/
})(jQuery, window, document);